// this is an autogenerated file from App/Auth/SignUpConfirm/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignUpConfirm_Content(
  $email: citext!
  $code: String!
  $contact_method_id: numeric!
  $password: String!
) {
  auth_patient_invite_register(
    email: $email
    code: $code
    contact_method_id: $contact_method_id
    password: $password
  ) {
    status
    access_token: jwt_token
    refresh_token
  }
}

`