// this is an autogenerated file from App/ChooseUser/Users/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_ChooseUser_Users{
  users {
    id
    vaxiom_person {
      id
      title
      greeting
      first_name
      last_name
      organization {
        id
        name
      }
      patient {
        id
      }
      from_person_relationships {
        id
        role
        to_person_record {
          id
          first_name
          last_name
          patient {
            id
          }
        }
      }
      employment_contracts {
        id
      }
      other_professional_relationships {
        id
        employee_type {
          id
          name
        }
        external_office {
          id
          name
          postal_address {
            id
            state
          }
        }
      }
    }
  }
}

`