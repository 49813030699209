// this is an autogenerated file from App/Auth/ResetPasswordConfirm/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_ResetPasswordConfirm($email: citext!, $code: String!, $password: String!) {
  auth_reset_password(email: $email, code: $code, password: $password) {
    status
    access_token: jwt_token
    refresh_token
  }
}

`