import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || data.length === 0) {
      return {
        balance: 0,
        due_now: 0,
        future_due: 0,
      }
    }

    return data[0]
  }, [data])
}
