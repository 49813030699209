import { useEffect } from 'react'

let celebrateModule = import('canvas-confetti')

// https://github.com/catdad/canvas-confetti#options
export default function Celebrate({
  colors = '#58bb73 #f2f609',
  particleCount = 50,
  angle = 90,
  spread = 360,
  startVelocity = 30,
  decay = 0.9,
  ticks = 60,
  shapes = ['square'],
  interval = 250,
} = {}) {
  useEffect(() => {
    let celebrateCanvas = null
    let intervalId = null

    async function run() {
      let module = await celebrateModule

      celebrateCanvas = document.createElement('canvas')
      celebrateCanvas.style.width = '100vw'
      celebrateCanvas.style.height = '100vh'
      celebrateCanvas.style.position = 'fixed'
      celebrateCanvas.style.top = '0px'
      celebrateCanvas.style.left = '0px'
      celebrateCanvas.style.zIndex = 1000000
      celebrateCanvas.style.pointerEvents = 'none'
      document.body.appendChild(celebrateCanvas)

      let celebrator = module.create(celebrateCanvas, {
        resize: true,
        useWorker: true,
      })

      let end = Date.now() + 4 * 1000

      intervalId = setInterval(function () {
        if (Date.now() > end) {
          clear()
          return
        }

        celebrator({
          colors: typeof colors === 'string' ? colors.split(' ') : colors,
          shapes: typeof shapes === 'string' ? shapes.split(' ') : shapes,
          particleCount,
          decay,
          angle,
          startVelocity,
          spread,
          ticks,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2,
          },
        })
      }, interval)
    }
    run()

    function clear() {
      try {
        clearInterval(intervalId)

        if (celebrateCanvas) {
          document.body.removeChild(celebrateCanvas)
        }
      } catch (_) {}
    }

    return clear
  }, [
    angle,
    colors,
    decay,
    interval,
    particleCount,
    shapes,
    spread,
    startVelocity,
    ticks,
  ])

  return null
}
