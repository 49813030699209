import { range, formatNumber } from './format.js'

export function rangeWithText(start, end) {
  return range(start, end).map(value => ({ id: value, text: value }))
}

export function timeRange(start, end, increment = 1) {
  return range(start, end, increment).map(value => ({
    id: value,
    text: formatNumber(value, '0#'),
  }))
}

export function join(...values) {
  return values.join(' ')
}

export function any(...values) {
  return values.find(Boolean)
}

export function attachmentsPrefix(parentCompanyId, threadId) {
  return `companies/${parentCompanyId}/threads/${threadId}/attachments`
}

export function equals(a, b) {
  return a === b
}

export function not(value, valueCompare) {
  return value !== valueCompare
}

export function includes(list, value) {
  return Array.isArray(list) && list.includes(value)
}

export function listEquals(a, b) {
  if (a.length === b.length) {
    return a.every(element => {
      if (b.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}

export function minus(...values) {
  return values.reduce(
    (acc, item, index) => (index === 0 ? item : acc - item),
    0
  )
}

export function plus(...values) {
  return values.reduce((acc, item) => acc + item, 0)
}
