import { isAccessTokenValid, useRefreshToken } from 'Data/Auth.js'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let auth = useDataValue({
    context: 'auth',
    viewPath: props.viewPath,
  })
  let refreshToken = useRefreshToken({ viewPath: props.viewPath })

  let redirect = useDataValue({
    context: 'flow_shortcuts',
    path: 'redirect',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    let cancel = false

    ;(async () => {
      try {
        if (isAccessTokenValid(auth)) {
          return success()
        } else if (auth.refresh_token) {
          let nextAuth = await refreshToken()
          if (cancel) return

          if (nextAuth.access_token) return success()
        }
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.debug({
            type: props.viewPath,
            error,
            auth,
          })
        }
      }

      setFlowTo(normalizePath(props.viewPath, '../SignIn'))

      function success() {
        if (auth.api_role.endsWith('-require-user')) {
          setFlowTo(normalizePath(props.viewPath, '../../ChooseUser'))
        } else if (redirect) {
          redirect()
        } else {
          setFlowTo(normalizePath(props.viewPath, '../../Account'))
        }
      }
    })()

    return () => {
      cancel = true
    }
  }, []) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
