import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import React, { useEffect } from 'react'
import View from './view.js'

let FIVE_MINUTES = 1000 * 60 * 5

export default function Logic(props) {
  let [, executeMutation] = useMutation(mutation)

  useEffect(() => {
    let cancel = false
    let interval = setInterval(() => {
      if (cancel) return

      executeMutation({
        app_name: process.env.REACT_APP_NAME,
        app_version: process.env.REACT_APP_VERSION,
      })
    }, FIVE_MINUTES)

    return () => {
      cancel = true
      clearInterval(interval)
    }
  }, [executeMutation])

  return <View viewPath={props.viewPath} />
}
