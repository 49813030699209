// this is an autogenerated file from App/Account/Content/Content/Home/Person/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Content_Home_Person($id: numeric!) {
  vaxiom_persons_by_pk(id: $id) {
    id
    first_name
  }
}

`