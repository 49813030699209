// this is an autogenerated file from App/Account/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account{
  patient_feature_flags_enabled {
    id
    appointments_cancel
    appointments_schedule
    appointments_reschedule
    comms_enabled
    login_enabled
    theme
    is_new_payments_enabled
  }
}

`