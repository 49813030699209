// this is an autogenerated file from App/ChooseUser/mutation-login-user.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_ChooseUser($user_id: uuid!) {
  auth_login_user(user_id: $user_id) {
    status
    access_token: jwt_token
    refresh_token
    inactivity_timeout
  }
}

`