export let EMPTY_PLACEHOLDER = 'Please answer this question'
export let STORAGE_KEY = 'PL'
export let STORAGE_KEY_IMAGE_CAPTURE = 'IC'
export let STORAGE_KEY_AUTH = 'AUTH'

let NEW = 'new'
let OPEN = 'open'
let PENDING = 'pending'
let ONHOLD = 'onhold'
let CLOSED = 'closed'

export let MESSAGE_ACTIONS = {
  withMessage: {
    [NEW]: 'Send and Open',
    [OPEN]: 'Send and Open',
    [PENDING]: 'Send and Set To Pending',
    [ONHOLD]: 'Send and Put On Hold',
    [CLOSED]: 'Send and Close',
  },

  noMessage: {
    [NEW]: 'Open',
    [OPEN]: 'Open',
    [PENDING]: 'Set To Pending',
    [ONHOLD]: 'Put On Hold',
    [CLOSED]: 'Close',
  },

  tags: {
    [NEW]: 'New',
    [OPEN]: 'Open',
    [PENDING]: 'Pending',
    [ONHOLD]: 'On Hold',
    [CLOSED]: 'Close',
  },
}

export let THREAD_STATUS_ORDERED_WITH_INDEX = {
  [NEW]: 0,
  [OPEN]: 1,
  [PENDING]: 2,
  [ONHOLD]: 3,
  [CLOSED]: 4,
}

export let THREAD_STATUSES = {
  [NEW]: NEW,
  [OPEN]: OPEN,
  [PENDING]: PENDING,
  [ONHOLD]: ONHOLD,
  [CLOSED]: CLOSED,
}

export let THREAD_STATUS_ORDERED = [NEW, OPEN, PENDING, ONHOLD, CLOSED]
export let THREAD_STATUS_ORDERED_WITHOUT_NEW = [OPEN, PENDING, ONHOLD, CLOSED]

export let INSTALLMENT_INTERVALS = {
  EverySecondWeek: {
    label: 'Bi-weekly',
    payments_per_month: 2,
  },
  Weekly: {
    label: 'Weekly',
    payments_per_month: 4,
  },
  TwicePerMonth: {
    label: 'Twice Per Month',
    payments_per_month: 2,
  },
  Monthly: {
    label: 'Monthly',
    payments_per_month: 1,
  },
  None: {
    label: 'None',
    payments_per_month: 0,
  },
}
export let DOWNPAYMENT_STEP = 10
