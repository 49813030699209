import View from './view.js'
import React from 'react'
import get from 'lodash/get'

export default function Logic(props) {
  return <View {...props} text={getText(props)} />

  function getText(props) {
    let selected = props.from.filter(props.isSelected)
    return selected.length <= 1
      ? get([...selected, null][0], `${props.field}`, props.text || 'Select')
      : `${selected.length} selected`
  }
}
Logic.defaultProps = {
  from: [],
}
