import { useMutation } from 'Data/Api.js'
import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { login } from 'Data/format.js'
import {
  email as isValidEmail,
  password as isValidPassword,
} from 'Data/validate.js'
import {
  notifyError,
  notifyInvalid,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import React, { useMemo } from 'react'
import View from './view.js'

export default function Logic(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let dataEmail = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'email',
  })
  let value = useMemo(
    () => ({
      email: dataEmail || '',
      password: '',
    }),
    [] // eslint-disable-line
    // ignore dataEmail
  )
  let [, executeMutation] = useMutation(mutation)

  return (
    <DataProvider
      viewPath={props.viewPath}
      context="signin"
      value={value}
      onSubmit={onSubmit}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )

  async function onSubmit({ value: next }) {
    if (isInvalid(next, notify)) return true

    let mutationResponse = await executeMutation(next, {
      fetchOptions: {
        headers: {
          'x-hasura-role': 'public',
        },
      },
    })

    if (
      mutationResponse.error ||
      (mutationResponse.data.auth_login.status !== 'ok' &&
        mutationResponse.data.auth_login.status !== 'require_user')
    ) {
      return notify(notifyError('Please verify your credentials'))
    }

    changeAuth(login(mutationResponse.data.auth_login))
    setFlowTo(normalizePath(props.viewPath, '../Check'))
  }

  function isInvalid(next, notify) {
    let list = [
      !isValidEmail(next.email) && 'email',
      !isValidPassword(next.password) && 'password',
    ].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
