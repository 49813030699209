import { phoneNumberUSLastFour } from 'Data/format.js'

export function getContactMethodValue(value) {
  if (!value.contact_method_id) return ''

  let cm = value.contact_methods.find(
    item => item.id === value.contact_method_id
  ).value
  return value.contact_method_type === 'sms' ? phoneNumberUSLastFour(cm) : cm
}
