// this is an autogenerated file from App/Auth/SignUp/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignUp($email: citext!) {
  auth_patient_invite(email: $email) {
    ok
  }
}

`