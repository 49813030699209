import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { phoneNumberUSLastFour } from 'Data/format.js'
import mutation from './mutation.graphql.js'

export function useResendCode({ viewPath }) {
  let contactMethodType = useDataValue({
    context: 'signup_confirm_request',
    path: 'contact_method_type',
    viewPath,
  })
  let contactMethods = useDataValue({
    context: 'signup_confirm_request',
    path: 'contact_methods',
    viewPath,
  })
  let dataEmail = useDataValue({
    context: 'flow_shortcuts',
    path: 'email',
    viewPath,
  })
  let dataCode = useDataValue({
    context: 'flow_shortcuts',
    path: 'code',
    viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function resendCode(contact_method_id) {
    let mutationResponse = await executeMutation({
      email: dataEmail,
      code: dataCode,
      contact_method_id,
    })

    let cm = contactMethods.find(item => item.id === contact_method_id).value
    let contact_method =
      contactMethodType === 'sms' ? phoneNumberUSLastFour(cm) : cm

    notify(
      mutationResponse.error
        ? notifyError(`Failed to send code to ${contact_method}`)
        : notifySuccess(`A new code is on its way to ${contact_method}`)
    )
  }
}
