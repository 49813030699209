import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { password as isValidPassword } from 'Data/validate.js'
import {
  notifyError,
  notifyInvalid,
  useNotifications,
} from 'Logic/Notifications.js'
import { login } from 'Data/format.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import View from './view.js'
import React from 'react'

let value = {
  password: '',
}

export default function Logic(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let dataEmail = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'email',
  })
  let dataCode = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'code',
  })

  return (
    <DataProvider
      viewPath={props.viewPath}
      context="reset_password_confirm"
      onSubmit={onSubmit}
      value={value}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )

  async function onSubmit({ value: next }) {
    if (isInvalid(next, notify)) return true

    let mutationResponse = await executeMutation(
      {
        email: dataEmail,
        code: dataCode,
        password: next.password,
      },
      {
        fetchOptions: {
          headers: {
            'x-hasura-role': 'public',
          },
        },
      }
    )

    if (
      mutationResponse.error ||
      (mutationResponse.data.auth_reset_password.status !== 'ok' &&
        mutationResponse.data.auth_reset_password.status !== 'require_user')
    ) {
      return notify(
        notifyError('Something went wrong. Please contact your clinic.')
      )
    }

    changeAuth(login(mutationResponse.data.auth_reset_password))
    setFlowTo(normalizePath(props.viewPath, '../Check'))
  }

  function isInvalid(next, notify) {
    let list = [!isValidPassword(next.password) && 'password'].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
