import { useDataChange, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let person_payment_account_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'person_payment_account_id',
  })
  let changeSelectedPersonPaymentAccountId = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'person_payment_account_id',
  })

  return async function onChange(value, change) {
    if (person_payment_account_id) return
    if (!value?.length) return

    changeSelectedPersonPaymentAccountId(value[0].id)
  }
}
