// this is an autogenerated file from App/Account/Content/Menu/FinancialAction/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Menu_FinancialAction($person_id: numeric!) {
  vaxiom_person_payment_accounts(
    where: { payer_person_id: { _eq: $person_id } }
  ) {
    id
  }
}

`