// this is an autogenerated file from App/Auth/SignUpConfirm/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignUpConfirm(
  $email: citext!
  $code: String!
  $contact_method_id: numeric
) {
  auth_patient_invite_request_verification(
    email: $email
    code: $code
    contact_method_id: $contact_method_id
  ) {
    contact_method_type
    contact_method_id
    contact_methods
  }
}

`