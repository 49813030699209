function maybeGetCacheExchangeKey(rfields) {
  let fields = rfields.filter(Boolean)
  return fields.length === rfields.length ? rfields.join(':') : null
}

let keys = {
  admin: {
    nps_rulesets_social_links: data =>
      maybeGetCacheExchangeKey([data.ruleset_id, data.type]),
    financial_payment_account_balances: () => null,
    financial_payment_account_balance_patients: () => null,
    appointment_request_slots: () => null,
    access_appointment_requests: () => null,
    access_appointment_request_slots: () => null,
    virtual_room_enter_response: () => null,
    aws_token_vending_machine: () => null,
    vaxiom_resource_object_meta: () => null,
    treatments_autodraft_payment_methods: () => null,
    discount_charge: () => null,
    custom_payment_plan_ruleset: () => null,
  },
  public: {},
}

export default keys
