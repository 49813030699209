import React from 'react'
import View from './view.js'

export default function Logic(props) {
  return <View viewPath={props.viewPath} onClick={onClick} />

  function onClick() {
    window.location.reload(true)
  }
}
