import 'Logic/polyfillObjectEntries.js'
import '@reach/dialog/styles.css'
import './index.css'
import './version.js'
import { Api } from 'Data/Api.js'
import { Auth } from 'Data/Auth.js'
import { ErrorBoundary } from 'Logic/ErrorBoundary.js'
import { FlowShortcuts } from 'Logic/FlowShortcuts.js'
import { Notifications } from 'Logic/Notifications.js'
import { DesignTokens } from 'Simple/DesignTokens'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from 'App/index.js'
import LoadingBarExpanded from 'design-system/LoadingBarExpanded/index.js'

// not ideal but...
let error = window.console.error
window.console.error = (...args) => {
  if (/(cannot appear as a descendant of|must have either an)/.test(args[0]))
    return

  error(...args)
}

let root = createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <App>
      {({ children, viewPath }) => (
        <DesignTokens viewPath={viewPath}>
          <FlowShortcuts viewPath={viewPath}>
            <Notifications viewPath={viewPath}>
              <Auth viewPath={viewPath}>
                <Api viewPath={viewPath}>
                  <ErrorBoundary viewPath={viewPath}>
                    <Suspense
                      fallback={<LoadingBarExpanded viewPath={viewPath} />}
                    >
                      {children}
                    </Suspense>
                  </ErrorBoundary>
                </Api>
              </Auth>
            </Notifications>
          </FlowShortcuts>
        </DesignTokens>
      )}
    </App>
  </ErrorBoundary>
)
