export * from './common-constants.js'
export let APP_NAME = process.env.REACT_APP_NAME
export let APP_API = process.env.REACT_APP_API
export let APP_ENV = process.env.REACT_APP_ENV
export let APP_VERSION = process.env.REACT_APP_VERSION
export let LOCAL_DEV_IP = process.env.REACT_APP_LOCAL_DEV_IP
export let SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY
export let S3_BUCKET_VAXIOM = process.env.REACT_APP_S3_BUCKET_VAXIOM
export let S3_BUCKET_FILES = process.env.REACT_APP_S3_BUCKET_FILES
// TODO: Removed them when the auth temporary token is implemented
export let ALLOWED_ROLES = [
  'treatment-calculator',
  'app-pay',
  'patient',
  'app-hub-require-user',
]
export let DEFAULT_ROLE = 'patient'
export let BLUEFIN_WIDGET_URL = process.env.REACT_APP_BLUEFIN_WIDGET_URL
export let PAYABLI_TOKEN = process.env.REACT_APP_PAYABLI_TOKEN
export let PAYABLI_WIDGET_URL = process.env.REACT_APP_PAYABLI_WIDGET_URL
