import { logout } from 'Data/format.js'
import { useDataChange } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let [, mutate] = useMutation(mutation)
  let change = useDataChange({
    context: 'auth',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    let cancel = false

    ;(async function () {
      try {
        await mutate()
        if (cancel) return
      } catch (error) {}

      change(logout())
    })()

    return () => {
      cancel = true
    }
  }, []) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
