import { useDataChange, useDataValue } from 'Simple/Data'
import { useResendCode } from '../../../useResendCode.js'

export function useOnClick(props) {
  let contactMethodId = useDataValue({
    context: 'signup_confirm_request',
    path: 'contact_method_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'signup_confirm_request',
    viewPath: props.viewPath,
  })

  let executeResendCode = useResendCode({ viewPath: props.viewPath })

  return async function onClick() {
    change(inext => {
      inext.can_request_new_code = false
    })

    await executeResendCode(contactMethodId)
  }
}
