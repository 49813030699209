// This file is automatically generated by Simple and will be overwritten
  // when the morpher runs. If you want to contribute to how it's generated, eg,
  // improving the algorithms inside, etc, see this:
  // https://github.com/use-simple/morpher/blob/master/ensure-is-media.js

  // based on https://github.com/streamich/use-media
import { useState, useEffect } from 'react'

function useMedia(
  query,
  defaultState = Boolean(window.matchMedia(query).matches)
) {
  let [state, setState] = useState(defaultState)

  useEffect(() => {
    let cancel = false
    let mediaQueryList = window.matchMedia(query)

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', onChange)
    } else {
      mediaQueryList.addListener(onChange)
    }
    setState(Boolean(mediaQueryList.matches))

    return () => {
      cancel = true
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', onChange)
      } else {
        mediaQueryList.removeListener(onChange)
      }
    }

    function onChange() {
      if (cancel) return
      setState(Boolean(mediaQueryList.matches))
    }
  }, [query])

  return state
}


  export default function useIsMedia() {
    return {
      "mobile": useMedia("(min-width: 0px) and (max-width: 440px)"),"tablet": useMedia("(min-width: 441px) and (max-width: 1024px)"),"laptop": useMedia("(min-width: 1025px)"),
      device: {
        "handheld": useMedia("(pointer: coarse)"),"desktop": useMedia("(pointer: fine), (pointer: none)"),"touch_screen_desktop": useMedia("(pointer: fine) and (any-pointer: coarse)")
      }
    }
  }
  