let yesOrNoOptions = [
  {
    id: true,
    value: true,
    text: 'Yes',
  },
  {
    id: false,
    value: false,
    text: 'No',
  },
]

export let patientHasGeneralDentistOptions = yesOrNoOptions

export let patientHasGeneralDentistOptionsSet = extractValues(patientHasGeneralDentistOptions)

export let patientAntibioticsOptions = yesOrNoOptions

export let patientAntibioticsOptionsSet = extractValues(patientAntibioticsOptions)

export let patientIsInPainOptions = yesOrNoOptions

export let patientIsInPainOptionsSet = extractValues(patientIsInPainOptions)

export let patientProblemPreviousProcedureOptions = yesOrNoOptions

export let patientProblemPreviousProcedureOptionsSet = extractValues(patientProblemPreviousProcedureOptions)

export let patientJawPainOptions = yesOrNoOptions

export let patientJawPainOptionsSet = extractValues(patientJawPainOptions)

export let patientGeneralHealthOptions = [
  {
    id: 'good',
    value: 'good',
    text: 'Good',
  },
  {
    id: 'fair',
    value: 'fair',
    text: 'Fair',
  },
  {
    id: 'poor',
    value: 'poor',
    text: 'Poor',
  },
]

export let patientGeneralHealthOptionsSet = extractValues(
  patientGeneralHealthOptions
)

export let patientDentalHealthOptions = [
  {
    id: 'good',
    value: 'good',
    text: 'Good',
  },
  {
    id: 'fair',
    value: 'fair',
    text: 'Fair',
  },
  {
    id: 'poor',
    value: 'poor',
    text: 'Poor',
  },
]

export let patientDentalHealthOptionsSet = extractValues(
  patientDentalHealthOptions
)

export let patientLikeSmileOptions = yesOrNoOptions

export let patientLikeSmileOptionsSet = extractValues(patientLikeSmileOptions)

export let patientGumsOptions = yesOrNoOptions

export let patientGumsOptionsSet = extractValues(patientGumsOptions)

export let patientPeriodontalDiseaseOptions = yesOrNoOptions

export let patientPeriodontalDiseaseOptionsSet = extractValues(patientPeriodontalDiseaseOptions)

export let patientToothbrushOptions = [
  {
    id: 'HARD',
    value: 'HARD',
    text: 'Hard',
  },
  {
    id: 'MEDIUM',
    value: 'MEDIUM',
    text: 'Medium',
  },
  {
    id: 'SOFT',
    value: 'SOFT',
    text: 'Soft',
  },
]

export let patientToothbrushOptionsSet = extractValues(
  patientToothbrushOptions
)

export let patientReactionsOptions = yesOrNoOptions

export let patientReactionsOptionsSet = extractValues(patientReactionsOptions)

export let patientRelativesCrowdingOptions = [
  {
    id: 'MOTHER',
    value: 'MOTHER',
    text: 'Mother',
  },
  {
    id: 'FATHER',
    value: 'FATHER',
    text: 'Father',
  },
  {
    id: 'SISTER',
    value: 'SISTER',
    text: 'Sister',
  },
  {
    id: 'BROTHER',
    value: 'BROTHER',
    text: 'Brother',
  },
  {
    id: 'GRANDMOTHER',
    value: 'GRANDMOTHER',
    text: 'Grandmother',
  },
  {
    id: 'GRANDFATHER',
    value: 'GRANDFATHER',
    text: 'Grandfather',
  },
  {
    id: 'AUNT',
    value: 'AUNT',
    text: 'Aunt',
  },
  {
    id: 'UNCLE',
    value: 'UNCLE',
    text: 'Uncle',
  },
  {
    id: 'CHILD',
    value: 'CHILD',
    text: 'Child',
  },
  {
    id: 'NEPHEW',
    value: 'NEPHEW',
    text: 'Nephew',
  },
  {
    id: 'NIECE',
    value: 'NIECE',
    text: 'Niece',
  },
  {
    id: 'GRANDSON',
    value: 'GRANDSON',
    text: 'Grandson',
  },
  {
    id: 'GRANDDAUGHTER',
    value: 'GRANDDAUGHTER',
    text: 'Granddaughter',
  },
]

export let patientRelativesCrowdingOptionsSet = extractValues(
  patientRelativesCrowdingOptions
)

export let patientClickingPainOptions = yesOrNoOptions

export let patientClickingPainOptionsSet = extractValues(patientClickingPainOptions)

export let patientHasPersonalPhysicianOptions = yesOrNoOptions

export let patientHasPersonalPhysicianOptionsSet = extractValues(patientHasPersonalPhysicianOptions)

export let patientActiveCareOptions = yesOrNoOptions

export let patientActiveCareOptionsSet = extractValues(patientActiveCareOptions)

export let patientColdOptions = yesOrNoOptions

export let patientColdOptionsSet = extractValues(patientColdOptions)

export let patientCancerMedicationsOptions = yesOrNoOptions

export let patientCancerMedicationsOptionsSet = extractValues(patientCancerMedicationsOptions)

export let patientHabitsOptions = [
  {
    id: 'thumb_sucking',
    value: 'thumb_sucking',
    text: 'Thumb sucking',
  },
  {
    id: 'mouth_breathing',
    value: 'mouth_breathing',
    text: 'Mouth breathing',
  },
  {
    id: 'grinding_teeth',
    value: 'grinding_teeth',
    text: 'Grinding of teeth',
  },
  {
    id: 'tongue_biting',
    value: 'tongue_biting',
    text: 'Tongue biting',
  },
  {
    id: 'smoking',
    value: 'smoking',
    text: 'Smoking',
  },
  {
    id: 'nail_biting',
    value: 'nail_biting',
    text: 'Nail biting',
  },
  {
    id: 'tongue_thrusting',
    value: 'tongue_thrusting',
    text: 'Tongue thrusting',
  },
  {
    id: 'speech_disorders',
    value: 'speech_disorders',
    text: 'Speech disorders',
  },
  {
    id: 'tongue_sucking',
    value: 'tongue_sucking',
    text: 'Tongue sucking',
  },
  {
    id: 'lip_biting',
    value: 'lip_biting',
    text: 'Lip biting',
  },
  {
    id: 'abnormal_breathing',
    value: 'abnormal_breathing',
    text: 'Abnormal breathing',
  }
]

export let patientHabitsOptionsSet = extractValues(
  patientHabitsOptions
)

export let patientTobaccoOptions = yesOrNoOptions

export let patientTobaccoOptionsSet = extractValues(patientTobaccoOptions)

export let patientBirthControlOptions = yesOrNoOptions

export let patientBirthControlOptionsSet = extractValues(patientBirthControlOptions)

export let patientPregnantOptions = yesOrNoOptions

export let patientPregnantOptionsSet = extractValues(patientPregnantOptions)

export let patientNursingOptions = yesOrNoOptions

export let patientNursingOptionsSet = extractValues(patientNursingOptions)

export let patientMedicalConditionsOptions = [
  {
    id: 'add_or_adhd',
    value: 'add_or_adhd',
    text: 'ADD or ADHD',
  },
  {
    id: 'aids',
    value: 'aids',
    text: 'AIDS or HIV infection',
  },
  {
    id: 'anemia',
    value: 'anemia',
    text: 'Anemia',
  },
  {
    id: 'arthritis',
    value: 'arthritis',
    text: 'Arthritis',
  },
  {
    id: 'artificial_bones_or_joints_or_valves',
    value: 'artificial_bones_or_joints_or_valves',
    text: 'Artificial bones/joints/valves',
  },
  {
    id: 'asthma',
    value: 'asthma',
    text: 'Asthma',
  },
  {
    id: 'autism_or_sensory',
    value: 'autism_or_sensory',
    text: 'Autism or sensory',
  },
  {
    id: 'blood_disorders',
    value: 'blood_disorders',
    text: 'Blood disorders',
  },
  {
    id: 'brain_injury',
    value: 'brain_injury',
    text: 'Brain injury',
  },
  {
    id: 'cancer',
    value: 'cancer',
    text: 'Cancer',
  },
  {
    id: 'heart_trouble',
    value: 'heart_trouble',
    text: 'Cardiac conditions',
  },
  {
    id: 'heart_congenital_defect',
    value: 'heart_congenital_defect',
    text: 'Congenital heart defect',
  },
  {
    id: 'diabetes',
    value: 'diabetes',
    text: 'Diabetes',
  },
  {
    id: 'dizziness_fainting',
    value: 'dizziness_fainting',
    text: 'Dizziness or fainting',
  },
  {
    id: 'drug_alcohol_abuse',
    value: 'drug_alcohol_abuse',
    text: 'Drug or alcohol abuse',
  },
  {
    id: 'emphysema',
    value: 'emphysema',
    text: 'Emphysema',
  },
  {
    id: 'epilepsy',
    value: 'epilepsy',
    text: 'Epilepsy or convulsions',
  },
  {
    id: 'herpes',
    value: 'herpes',
    text: 'Fever blister herpes',
  },
  {
    id: 'frequently_tired',
    value: 'frequently_tired',
    text: 'Frequently tired',
  },
  {
    id: 'glaucoma',
    value: 'glaucoma',
    text: 'Glaucoma',
  },
  {
    id: 'handicap_or_disabilities',
    value: 'handicap_or_disabilities',
    text: 'Handicaps or disabilities',
  },
  {
    id: 'hearing_impairment',
    value: 'hearing_impairment',
    text: 'Hearing impairment',
  },
  {
    id: 'heart_attack',
    value: 'heart_attack',
    text: 'Heart attack',
  },
  {
    id: 'heart_murmur',
    value: 'heart_murmur',
    text: 'Heart murmur',
  },
  {
    id: 'heart_surgery',
    value: 'heart_surgery',
    text: 'Heart surgery or pacemaker',
  },
  {
    id: 'hemophilia_or_abnormal_bleeding',
    value: 'hemophilia_or_abnormal_bleeding',
    text: 'Hemophilia or abnormal bleeding',
  },
  {
    id: 'hepatitis',
    value: 'hepatitis',
    text: 'Hepatitis or jaundice',
  },
  {
    id: 'high_blood_pressure',
    value: 'high_blood_pressure',
    text: 'High blood pressure',
  },
  {
    id: 'kidney_diseases',
    value: 'kidney_diseases',
    text: 'Kidney diseases',
  },
  {
    id: 'kidney_liver_disease',
    value: 'kidney_liver_disease',
    text: 'Liver disease',
  },
  {
    id: 'low_blood_pressure',
    value: 'low_blood_pressure',
    text: 'Low blood pressure',
  },
  {
    id: 'mitral_valve_prolapse',
    value: 'mitral_valve_prolapse',
    text: 'Mitral valve prolapse',
  },
  {
    id: 'psychiatric_problem',
    value: 'psychiatric_problem',
    text: 'Psychiatric problem',
  },
  {
    id: 'radiation_therapy',
    value: 'radiation_therapy',
    text: 'Radiation-therapy',
  },
  {
    id: 'respiratory_problems',
    value: 'respiratory_problems',
    text: 'Respiratory problems',
  },
  {
    id: 'rheumatic_fever',
    value: 'rheumatic_fever',
    text: 'Rheumatic scarlet fever',
  },
  {
    id: 'seizures',
    value: 'seizures',
    text: 'Seizures',
  },
  {
    id: 'shingles',
    value: 'shingles',
    text: 'Shingles',
  },
  {
    id: 'sickle_cell_disease',
    value: 'sickle_cell_disease',
    text: 'Sickle cell disease',
  },
  {
    id: 'stomach_troubles_or_ulcers',
    value: 'stomach_troubles_or_ulcers',
    text: 'Stomach troubles or ulcers',
  },
  {
    id: 'stroke',
    value: 'stroke',
    text: 'Stroke',
  },
  {
    id: 'thyroid_problem',
    value: 'thyroid_problem',
    text: 'Thyroid problem',
  },
  {
    id: 'tuberculosis',
    value: 'tuberculosis',
    text: 'Tuberculosis',
  },
]

export let patientMedicalConditionsOptionsSet = extractValues(
  patientMedicalConditionsOptions
)
export let patientGenderOptions = [
  {
    id: 'MALE',
    value: 'MALE',
    text: 'Male',
  },
  {
    id: 'FEMALE',
    value: 'FEMALE',
    text: 'Female',
  },
  {
    id: 'UNSPECIFIED',
    value: 'UNSPECIFIED',
    text: 'Prefer not to specify',
  },
]

export let patientAllergiesOptions = [
  {
    id: 'aspirin',
    value: 'aspirin',
    text: 'Aspirin',
  },
  {
    id: 'tetracycline',
    value: 'tetracycline',
    text: 'Tetracycline',
  },
  {
    id: 'sulfa drugs',
    value: 'sulfa drugs',
    text: 'Sulfa drugs',
  },
  {
    id: 'amoxicillin',
    value: 'amoxicillin',
    text: 'Amoxicillin',
  },
  {
    id: 'latex',
    value: 'latex',
    text: 'Latex',
  },
  {
    id: 'iodine',
    value: 'iodine',
    text: 'Iodine',
  },
  {
    id: 'penicillin',
    value: 'penicillin',
    text: 'Penicillin',
  },
  {
    id: 'jewelry/metals',
    value: 'jewelry/metals',
    text: 'Jewelry/metals',
  },
  {
    id: 'plastic',
    value: 'plastic',
    text: 'Plastic',
  },
  {
    id: 'codeine',
    value: 'codeine',
    text: 'Codeine',
  },
  {
    id: 'dental anesthetics',
    value: 'dental anesthetics',
    text: 'Dental anesthetics'
  }
]

export let patientAllergiesOptionsSet = extractValues(
  patientAllergiesOptions
)

/**
 *
 * Helpers
 *
 */

function extractValues(array) {
  return array.map(object => object.value)
}
