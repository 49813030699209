import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .map(item => {
        let is_patient = !!item.vaxiom_person.patient
        let is_payer = item.vaxiom_person.from_person_relationships?.length > 0
        let is_employee = item.vaxiom_person.employment_contracts?.length > 0

        let external_professional =
          item.vaxiom_person.other_professional_relationships?.[0]
        let is_external_professional = Boolean(external_professional)
        let external_professional_info = [
          external_professional?.employee_type?.name,
          external_professional?.external_office?.name,
          external_professional?.external_office?.postal_address?.state,
        ]
          .filter(Boolean)
          .join(', ')

        return {
          ...item,
          is_patient,
          is_payer,
          is_employee,
          is_external_professional,
          external_professional_info: external_professional_info || null,
        }
      })
      .filter(Boolean)
  }, [data]) // eslint-disable-line
  // ignore transform
}
