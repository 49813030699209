import React, { useEffect, useRef } from 'react'
import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

let ONE_MINUTE = 1000 * 60

export default function Logic(props) {
  let canRequestNewCode = useDataValue({
    context: 'signup_confirm_request',
    path: 'can_request_new_code',
    viewPath: props.viewPath,
  })
  let setCanRequestNewCode = useDataChange({
    context: 'signup_confirm_request',
    path: 'can_request_new_code',
    viewPath: props.viewPath,
  })

  let cancel = useRef(false)

  useEffect(() => {
    if (canRequestNewCode) return

    setTimeout(() => {
      if (cancel.current) return

      setCanRequestNewCode(true)
    }, ONE_MINUTE)

    return () => {
      cancel.current = true
    }
  }, [canRequestNewCode]) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
