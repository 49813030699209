import { useMemo } from 'react'
// import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  // let current_user = useDataValue({
  //   context: 'global',
  //   path: 'current_user',
  //   viewPath: props.viewPath,
  // })
  let current_user = null

  return useMemo(() => {
    if (!data) return data

    let preferredUserId = localStorage.getItem('preferredUserId')

    if (current_user) {
      return {
        user_id: current_user.xid,
      }
    } else if (preferredUserId) {
      return {
        user_id: preferredUserId,
      }
    } else {
      return data
    }
  }, [data, current_user])
}
