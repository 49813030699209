// this is an autogenerated file from App/Account/Content/Content/Home/AccountBalance/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Content_Home_AccountBalance_Content($payment_account_id: numeric) {
  financial_payment_account_balance(
    args: { account_id_input: $payment_account_id }
  ) {
    payment_account_id
    balance
    due_now
    future_due
  }
}

`