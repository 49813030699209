import { DataProvider } from 'Simple/Data.js'
import { email as isValidEmail } from 'Data/validate.js'
import {
  notifyError,
  notifyInvalid,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import React from 'react'
import View from './view.js'

let value = {
    email: '',
  }

export default function Logic(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return (
    <DataProvider
      viewPath={props.viewPath}
      context="reset_password"
      value={value}
      onSubmit={onSubmit}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )

  async function onSubmit({ value: next }) {
    if (isInvalid(next, notify)) return true

    let mutationResponse = await executeMutation(next, {
      fetchOptions: {
        headers: {
          'x-hasura-role': 'public',
        },
      },
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.auth_reset_password_request.ok
    ) {
      return notify(
        notifyError(
          `There was a problem processing your request. Please contact your clinic.`
        )
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../ResetPasswordSent'))
  }

  function isInvalid(next, notify) {
    let list = [!isValidEmail(next.email) && 'email'].filter(
      Boolean
    )

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
