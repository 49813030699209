// this is an autogenerated file from App/Account/Content/ReportUserLastSeen/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_ReportUserLastSeen($app_name: String!, $app_version: String!) {
  insert_iam_users_activity_one(
    object: {
      app_name: $app_name
      app_version: $app_version
      last_seen_at: "NOW()"
    }
    on_conflict: {
      constraint: users_activity_pkey
      update_columns: [last_seen_at]
      where: {}
    }
  ) {
    last_seen_at
  }
}

`