import React, { useMemo } from 'react'
import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { phoneNumberUSLastFour } from 'Data/format.js'
import { useResendCode } from '../../../useResendCode.js'

export default function Logic(props) {
  let contactMethodId = useDataValue({
    context: 'signup_confirm_request',
    path: 'contact_method_id',
    viewPath: props.viewPath,
  })
  let contactMethodType = useDataValue({
    context: 'signup_confirm_request',
    path: 'contact_method_type',
    viewPath: props.viewPath,
  })
  let contactMethods = useDataValue({
    context: 'signup_confirm_request',
    path: 'contact_methods',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'signup_confirm_request',
    viewPath: props.viewPath,
  })

  let executeResendCode = useResendCode({ viewPath: props.viewPath })

  let valueFrom = useMemo(() => {
    if (!contactMethods) return []

    return contactMethods
      .filter(item => item.id !== contactMethodId)
      .map(item => ({
        id: item.id,
        text:
          contactMethodType === 'sms'
            ? phoneNumberUSLastFour(item.value)
            : item.value,
      }))
  }, [contactMethods, contactMethodId, contactMethodType])

  return <View from={valueFrom} onClick={onClick} viewPath={props.viewPath} />

  async function onClick(next) {
    if (!next) return

    change(inext => {
      inext.can_request_new_code = false
      inext.contact_method_id = next
    })

    await executeResendCode(next)
  }
}
