import React, { useEffect, useState } from 'react'
import { DataProvider } from 'Simple/Data.js'
import View from './view.js'
let TEN_MINUTES = 1000 * 60 * 10

export default function Logic(props) {
  let [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(() => {
    let cancel = false

    async function checkVersion() {
      try {
        let response = await fetch(`/version.json?t=${Date.now()}`)
        let versionData = await response.json()
        if (cancel) return

        if (versionData?.version !== window?.greyfinch[0]?.version) {
          setShouldUpdate(true)
        }
      } catch (err) {
        console.log(`Can't determine version`)
      }
    }

    checkVersion()
    let interval = setInterval(checkVersion, TEN_MINUTES)

    return () => {
      cancel = true
      clearInterval(interval)
    }
  }, []) // eslint-disable-line

  return (
    <DataProvider
      viewPath={props.viewPath}
      context="shouldUpdate"
      value={shouldUpdate}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )
}
