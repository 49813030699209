// import { today } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api'
import { login } from 'Data/Auth'
import mutationLoginUser from './mutation-login-user.graphql.js'
import { useSetFlowTo } from 'Simple/Flow.js'

export default function useDataOnSubmit(props) {
  let [, notify] = useNotifications()

  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })
  let [, executeMutationLoginUser] = useMutation(mutationLoginUser)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ args }) {
    let { current_user } = args

    // if the current location and the chosen location are the same
    // don't execute the mutation or it will override the saved chairs
    if (user_id === current_user.id) {
      setFlowTo('/App/Account')
      return
    }

    let mutationResponseLoginUser = await executeMutationLoginUser({
      user_id: current_user.id,
    })
    if (
      mutationResponseLoginUser.error ||
      mutationResponseLoginUser.data.auth_login_user.status !== 'ok'
    ) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact the clinic if the problem persists.'
        )
      )
      return
    }

    // localStorage.setItem('choseUserAfterLogin', today())
    localStorage.setItem('preferredUserId', current_user.id)

    changeAuth(login(mutationResponseLoginUser.data.auth_login_user))

    setFlowTo('/App/Account')
  }
}
