// Auth works together with Api. There are some extra notes on Api.js that
// complement these.
//
// It stores its access and refresh tokens in local storage and coordinates
// refreshing the token across tabs using local storage events.
//
// It also has a few fallback mechanisms to catch race conditions when a token
// was refreshed by another tab but the current tab made a request to refresh
// the token with the same (recently used) refresh token that resulted in an
// unauthorized request, so at that point we look into local storage just in
// case another tab got the right token.
//
// Should anything fail, the user is redirected to /App/Auth/SignOut.
//
// When a user is signed in on one tab, opening another one will log them in
// automatically.
// However, if a user had two or more logged out tabs and logs in on one of
// them, the others won't be automatically logged in. We could enable this
// through local storage events but I think it's probably ok to leave this
// as is for now.
//
import { useSetFlowTo } from 'Simple/Flow.js'
import { captureBreadcrumb } from 'Logic/ErrorBoundary.js'
import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import {
  localStorage,
  windowAddEventListenerStorage,
  windowRemoveEventListenerStorage,
} from 'Logic/localStorage'
import { useMutation } from 'Data/Api.js'
// we need to import gql directly in here otherwise Webpack can't find the reference
import { gql } from 'urql'
import makeDebug from 'Simple/debug.js'
import toSnakeCase from 'to-snake-case'
import React, { useEffect, useMemo, useRef } from 'react'
import { ALLOWED_ROLES, DEFAULT_ROLE } from 'Data/constants'

let debug = makeDebug('simple/Auth')

let mutationRefreshToken = gql`
  mutation auth_refresh_token($refresh_token: String) {
    auth_refresh_token(refresh_token: $refresh_token) {
      status
      access_token: jwt_token
      refresh_token
    }
  }
`

let AUTH_KEY = 'auth'

export function Auth(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useMemo(() => getAuthFromLocalStorage(), [])

  return (
    <DataProvider
      context="auth"
      onChange={onChange}
      value={value}
      viewPath={props.viewPath}
    >
      <Sync viewPath={props.viewPath} />
      {props.children}
    </DataProvider>
  )

  async function onChange(next) {
    let data = { api_role: next.api_role }
    if (data.api_role && data.api_role !== 'public') {
      data.id = next.access_token_data?.user_id
    }

    captureBreadcrumb({ category: 'auth', data })

    // by checking for the non-existence of the localstorage item within the
    // onchange of the auth provider we can know that an explicit logout has
    // happened and logout other open tabs
    if (
      data.api_role === 'patient' &&
      !hasLocalStorageAuthentication() &&
      next.access_token
    ) {
      setFlowTo(props.authSignOutView)
    }
  }
}

Auth.defaultProps = {
  authSignOutView: '/App/Auth/SignOut',
}

function hasLocalStorageAuthentication() {
  return !!localStorage.getItem(AUTH_KEY)
}

function Sync(props) {
  let change = useDataChange({ context: 'auth', viewPath: props.viewPath })

  useEffect(() => {
    function listener(event) {
      if (event.key === AUTH_KEY) {
        debug({
          type: 'Sync',
          event,
        })

        change(getAuthFromLocalStorage())
      }
    }

    windowAddEventListenerStorage(listener)

    return () => {
      windowRemoveEventListenerStorage(listener)
    }
  }, [change])

  return null
}

export function useAuthRef({ viewPath }) {
  let data = useDataValue({ context: 'auth', viewPath: viewPath })
  let change = useDataChange({ context: 'auth', viewPath })
  let ref = useRef({ data, change })

  useEffect(() => {
    ref.current = { data, change }
  }, [data, change])

  return ref
}

export function useRefreshToken({ viewPath }) {
  let [, mutate] = useMutation(mutationRefreshToken)
  let refresh_token = useDataValue({
    viewPath,
    context: 'auth',
    path: 'refresh_token',
  })
  let authDataChange = useDataChange({
    viewPath,
    context: 'auth',
  })

  return async function _refreshToken() {
    if (!refresh_token) return {}

    debug({ type: 'useRefreshToken/_refreshToken', refresh_token })

    let nextAuth = await refreshToken({
      mutate: (_, variables, context) => mutate(variables, context),
      refresh_token,
    })
    authDataChange(nextAuth)
    return nextAuth
  }
}

export async function refreshToken({ mutate, refresh_token }) {
  if (!refresh_token) {
    return logout()
  }
  debug({ type: 'Auth/refreshToken', refresh_token })

  if (
    !claimRefreshTokenRight(refresh_token) &&
    (await waitForTokenToBeRefreshed())
  ) {
    return getAuthFromLocalStorage()
  }

  debug({ type: 'Auth/refreshToken/got-lock', refresh_token })

  try {
    let mutationResponse = await mutate(
      mutationRefreshToken,
      { refresh_token },
      {
        fetchOptions: {
          headers: {
            'x-hasura-role': 'public',
          },
        },
      }
    )

    debug({ type: 'Auth/refreshToken', mutationResponse })

    if (
      mutationResponse.error ||
      mutationResponse.data.auth_refresh_token.status !== 'ok'
    ) {
      // we could run into a race condition when working with multiple tabs, so
      // instead of logging the user out, let's get the value from local storage
      // because it might have just been updated
      return getAuthFromLocalStorage()
    } else {
      return login(mutationResponse.data.auth_refresh_token)
    }
  } catch (error) {
    debug({ type: 'Auth/refreshToken', error })
    return getAuthFromLocalStorage()
  } finally {
    releaseRefreshTokenRight(refresh_token)
  }
}

function getRefreshTokenRightKey(refresh_token) {
  return `refresh_token_right_${refresh_token}`
}

function claimRefreshTokenRight(refresh_token) {
  let key = getRefreshTokenRightKey(refresh_token)

  if (localStorage.getItem(key)) {
    return false
  } else {
    localStorage.setItem(key, Date.now())
    return true
  }
}

function releaseRefreshTokenRight(refresh_token) {
  localStorage.removeItem(getRefreshTokenRightKey(refresh_token))
}

async function waitForTokenToBeRefreshed(timeout = 10000) {
  return new Promise(resolve => {
    function listener(event) {
      if (event.key === AUTH_KEY) {
        debug({
          type: 'waitForTokenToBeRefreshed',
          event,
        })

        windowRemoveEventListenerStorage(listener)
        clearTimeout(timeoutId)
        resolve(true)
      }
    }

    windowAddEventListenerStorage(listener)

    let timeoutId = setTimeout(() => {
      windowRemoveEventListenerStorage(listener)
      resolve(false)
    }, timeout)
  })
}

/**
 * @param {{
 *   access_token?: string
 *   refresh_token?: string
 * }} params
 * @param {boolean} saveLocalStorage - default: true
 * @returns {{
 *   access_token?: string
 *   access_token_data?: any
 *   refresh_token?: string
 *   api_role: 'patient'
 * }}
 */
export function login(
  { access_token = null, refresh_token = null },
  saveLocalStorage = true
) {
  let access_token_data = getAccessTokenData(access_token)

  let value = (access_token || refresh_token) && {
    access_token,
    refresh_token,
  }
  if (value && saveLocalStorage) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(value))
  } else {
    localStorage.removeItem(AUTH_KEY)
  }

  let api_role = access_token_data.allowed_roles?.find(role =>
    ALLOWED_ROLES.includes(role)
  )

  return {
    access_token,
    access_token_data,
    api_role: api_role ? api_role : DEFAULT_ROLE,
    refresh_token,
  }
}
export function getAccessTokenData(token) {
  if (token === null) return {}
  try {
    let data = JSON.parse(atob(token.split('.')[1]))
    // eslint-disable-next-line compat/compat
    let claims = Object.fromEntries(
      // eslint-disable-next-line compat/compat
      Object.entries(data['https://hasura.io/jwt/claims']).map(
        ([key, value]) => [toSnakeCase(key.replace(/^x-hasura-/, '')), value]
      )
    )

    return {
      ...data,
      ...claims,
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'development') {
      debug({ type: 'auth/getAccessTokenData', error, token })
    }
    return {}
  }
}

export function logout() {
  localStorage.removeItem('aws_credentials')
  localStorage.removeItem(AUTH_KEY)

  return {
    access_token: null,
    access_token_data: {},
    api_role: 'public',
    refresh_token: null,
  }
}

export function getTimeToTokenExpirationInMs({
  access_token,
  access_token_data,
}) {
  if (!access_token) return 0

  return Math.ceil(access_token_data.exp - Date.now() / 1000) * 1000
}

export function isTokenExpired({ access_token, access_token_data }) {
  return log(access_token === null || Date.now() / 1000 > access_token_data.exp)

  function log(value) {
    if (value) {
      debug({
        type: 'Auth/isTokenExpired',
        value,
        expires_in_ms: getTimeToTokenExpirationInMs({
          access_token,
          access_token_data,
        }),
        access_token,
        access_token_data,
      })
    }
    return value
  }
}

export function isAccessTokenValid(auth) {
  return !isTokenExpired(auth)
}

function getAuthFromLocalStorage() {
  try {
    let { access_token, refresh_token } = JSON.parse(
      localStorage.getItem(AUTH_KEY)
    )
    if (access_token || refresh_token) {
      let value = login({ access_token, refresh_token })
      return isTokenExpired(value)
        ? login({ access_token: null, refresh_token })
        : value
    }
  } catch (error) {}

  return logout()
}
