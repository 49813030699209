// this is an autogenerated file from App/Auth/SignIn/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignIn($email: citext!, $password: String!) {
  auth_login(email: $email, password: $password) {
    status
    access_token: jwt_token
    refresh_token
  }
}

`