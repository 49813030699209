import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import {
  password as isValidPassword,
  signupCode as isValidSignupCode,
} from 'Data/validate.js'
import {
  notifyError,
  notifyInvalid,
  useNotifications,
} from 'Logic/Notifications.js'
import { login } from 'Data/format.js'
import { useHistory } from 'Logic/FlowShortcuts.js'
import { useMutation } from 'Data/Api.js'
// import { useSetFlowTo } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
// import useIsMedia from 'Simple/hooks/useIsMedia.js'
import View from './view.js'
import React from 'react'

let value = {
  code: '',
  password: '',
}

export default function Logic(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  // let isMedia = useIsMedia()
  let dataEmail = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'email',
  })
  let contact_method_id = useDataValue({
    viewPath: props.viewPath,
    context: 'signup_confirm_request',
    path: 'contact_method_id',
  })
  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  // let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let history = useHistory()

  return (
    <DataProvider
      viewPath={props.viewPath}
      context="signup_confirm"
      value={value}
      onSubmit={onSubmit}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )

  async function onSubmit({ value: next }) {
    if (isInvalid(next, notify)) return true

    let mutationResponse = await executeMutation(
      {
        email: dataEmail,
        contact_method_id,
        password: next.password,
        code: next.code,
      },
      {
        fetchOptions: {
          headers: {
            'x-hasura-role': 'public',
          },
        },
      }
    )

    if (
      mutationResponse.error ||
      mutationResponse.data.auth_patient_invite_register.status !== 'ok'
    ) {
      return notify(notifyError('Please verify your credentials'))
    }

    // NOTE: deprioritised the seperate mobile handling
    // if (isMedia.mobile) {
    //   setFlowTo('/App/Account/OnboardingMobile')
    // }

    changeAuth(login(mutationResponse.data.auth_patient_invite_register))
    history.push('/')
    setFlowTo(normalizePath(props.viewPath, '../../Check'))
  }

  function isInvalid(next, notify) {
    let list = [
      !isValidSignupCode(next.code) && 'code',
      // TODO replace for other platforms, this is only for vaxiom/core
      !isValidPassword(next.password) && 'password',
    ].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
