import React from 'react'
import View from './view.js'
import { numberMoney } from 'Data/format.js'

export default function Logic(props) {
  return (
    <View
      {...props}
      dueNow={numberMoney(props.value?.due_now || 0)}
      isZero={props.value?.due_now === 0}
    />
  )
}
