// this is an autogenerated file from App/Account/Content/Content/Home/MessageAlert/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Content_Home_MessageAlert_Content{
  comms_thread_user(where: { status: { _in: [pending] } }) {
    id
    status
    type
    name
    events(
      where: { in_app: { is_inbound: { _eq: false } } }
      limit: 1
      order_by: { created_at: desc }
    ) {
      id
      in_app {
        id
        outbound_location {
          id
          name
        }
      }
    }
  }
}

`