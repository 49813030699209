import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let payment_account_id = useDataValue({
    context: 'selected',
    path: 'person_payment_account_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      payment_account_id,
    },
    pause: !payment_account_id,
  }
}
