import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { logout } from 'Data/format.js'
import mutation from './mutation.graphql.js'
import React, { useEffect, useState } from 'react'
import View from './view.js'

let ACCOUNT_EXISTS_CODE = 'account-exists'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let dataEmail = useDataValue({
    context: 'flow_shortcuts',
    path: 'email',
    viewPath: props.viewPath,
  })
  let dataCode = useDataValue({
    context: 'flow_shortcuts',
    path: 'code',
    viewPath: props.viewPath,
  })

  let dataAuthAccessToken = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token',
  })
  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })

  let [, executeMutation] = useMutation(mutation)

  let [value, setValue] = useState({
    contact_method_type: null,
    contact_method_id: null,
    contact_methods: [],
    can_request_new_code: true,
  })

  useEffect(() => {
    // logout whatever user was logged in and send to check auth
    // TODO: the value could be null or undefined because of how the auth data provider
    // defines its initial state { todo: true } - update it once it changes
    if (!!dataAuthAccessToken) {
      changeAuth(logout())
      setFlowTo(normalizePath(props.viewPath, '../Check'))
      return
    }

    if (!dataEmail || !dataCode) {
      setFlowTo(normalizePath(props.viewPath, 'Error'))
      return
    }

    let cancel = false
    ;(async function () {
      setFlowTo(normalizePath(props.viewPath, 'Loading'))

      let mutationResponse = await executeMutation({
        email: dataEmail,
        code: dataCode.toString(),
      })
      if (cancel) return

      // if account already set up, send to check auth
      if (mutationResponse.error) {
        mutationResponse.error?.graphQLErrors?.[0]?.extensions?.code ===
        ACCOUNT_EXISTS_CODE
          ? setFlowTo(normalizePath(props.viewPath, '../Check'))
          : setFlowTo(normalizePath(props.viewPath, 'Error'))
      } else {
        setFlowTo(normalizePath(props.viewPath, 'Content'))

        let { contact_method_type, contact_method_id, contact_methods } =
          mutationResponse.data.auth_patient_invite_request_verification

        setValue({
          contact_method_type,
          contact_method_id,
          contact_methods,
          can_request_new_code: true,
        })
      }
    })()

    return () => {
      cancel = true
    }
  }, []) // eslint-disable-line
  // ignore deps

  return (
    <DataProvider
      context="signup_confirm_request"
      value={value}
      viewPath={props.viewPath}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )
}
