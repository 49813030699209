// this is an autogenerated file from App/Auth/SignOut/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignOut{
  auth_logout {
    ok
  }
}

`