import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let patients = []
    let nextUnScheduledAppointments = []
    let unScheduledAppointments = []
    let nextUnScheduledAppointmentIds = []
    let remoteMonitoringAppointmentsInProgress = []

    // get remote monitoring in progress and not completed
    remoteMonitoringAppointmentsInProgress = data
      .filter(appointment =>
        ['CHECKED_IN'].includes(appointment.appointment_bookings[0]?.state)
      )
      .filter(
        appointment =>
          appointment?.remote_monitoring_requests &&
          appointment?.remote_monitoring_requests[0]?.status === 'created'
      )

    // get appointment details already scheduled, or previously cancelled to be rescheduled
    // we don't want to show cancelled emergencies where the clinic hasn't deleted them (when they should have)
    // If I include the filter in the query I get empty appointment_booking results for states where there there have actually been bookings.
    // The problem with this is I can’t determine what the next unscheduled appointment is,
    // i.e. where there are truly no appointment_bookings associated with the appointment.
    // NO_SHOW = missed in our frontend
    let scheduledAppointments = data
      .filter(appointment =>
        [
          'SCHEDULED',
          'OFFICE_CANCELLED',
          'PATIENT_CANCELLED',
          'NO_SHOW',
        ].includes(appointment.appointment_bookings[0]?.state)
      )
      .filter(
        appointment =>
          !(
            appointment.type?.full_name.toLowerCase() === 'emergency' &&
            appointment.appointment_bookings[0]?.state === 'OFFICE_CANCELLED'
          )
      )

    // find the completed appointments based on checked_out, and sort to get them in order of date. If same date order by id
    // a completed appointment will generally have a next_appointment_id to use for the next unscheduled, if we haven't got a scheduled one
    let completedAppointments = data
      .filter(appointment =>
        ['CHECKED_OUT'].includes(appointment.appointment_bookings[0]?.state)
      )
      .filter(
        appointment =>
          !(appointment.type?.full_name.toLowerCase() === 'emergency')
      )
      .sort((a, b) => {
        return b?.appointment_bookings?.[0]?.start_time ===
          a?.appointment_bookings?.[0]?.start_time
          ? b?.id - a?.id
          : new Date(b?.appointment_bookings?.[0]?.start_time) -
              new Date(a?.appointment_bookings?.[0]?.start_time)
      })

    // track already scheduled appointments for each patient
    scheduledAppointments.forEach(appointment => {
      if (!patients.includes(appointment.patient.id)) {
        patients.push(appointment.patient.id)
      }
    })

    // find the next_appointment_id for each patient if haven't already a scheduled appointment
    nextUnScheduledAppointmentIds = completedAppointments?.map(appointment => {
      if (!patients.includes(appointment.patient.id)) {
        patients.push(appointment.patient.id)
        return appointment.next_appointment_id
      }
      return false
    })

    // get the next appointment if we have it
    if (nextUnScheduledAppointmentIds.length > 0) {
      nextUnScheduledAppointments = data
        .map(appointment => {
          if (nextUnScheduledAppointmentIds.includes(appointment.id)) {
            return appointment
          }
          return false
        })
        .filter(Boolean)
    }

    // for each patient not already with a next scheduled or unscheduled appointment, find the first empty appointment_booking
    unScheduledAppointments = data
      .map(appointment => {
        if (
          !patients.includes(appointment.patient.id) &&
          appointment.appointment_bookings.length === 0
        ) {
          patients.push(appointment.patient.id)
          return appointment
        }

        return false
      })
      .filter(Boolean)

    return [...remoteMonitoringAppointmentsInProgress].concat(
      [
        ...scheduledAppointments,
        ...nextUnScheduledAppointments,
        ...unScheduledAppointments,
      ].sort(
        (a, b) =>
          new Date(a?.appointment_bookings?.[0]?.start_time) -
          new Date(b?.appointment_bookings?.[0]?.start_time)
      )
    )
  }, [data])
}
